import React, { useEffect } from "react";
import Metrics from "helpers/metrics";
import { ImgContainer, Container } from "./components";
import theme from "theme";

export default () => {
  useEffect(() => {
    Metrics.track("plan-error");
  }, []); // eslint-disable-line

  return (
    <Container>
      <p-content>
        <h1>Whoops, something went wrong</h1>
        <p>
          <p-lead>
            Please try again later or email us at{" "}
            <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
          </p-lead>
        </p>
      </p-content>
      <ImgContainer>
        <img src={theme.errorImage} alt="bear para-gliding towards mailbox" />
      </ImgContainer>
    </Container>
  );
};
