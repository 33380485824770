import React, { useCallback } from "react";
import styled from "styled-components";
import Select from "react-select";
import { useLingui } from "@lingui/react";
import CanadaF from "images/canada.png";
import PanamaF from "images/panama.png";
import SingaporeF from "images/singapore.png";
import USAF from "images/usa.png";
import UAEF from "images/uae.png";
import { useFeatureToggles } from "hooks";
import theme from "theme";

const flags = {
  USA: USAF,
  UAE: UAEF,
  PAN: PanamaF,
  SING: SingaporeF,
  CA: CanadaF,
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    opacity: state.isSelected ? "0.5" : "1",
  }),
  control: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    outline: "0 !important",
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    position: "absolute",
    right: "4%",
    color: theme.midGrey,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    flex: "1",
    maxWidth: "80%",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 4,
  }),
};

const allLanguageRegions = [
  {
    value: "us",
    region: "USA",
    locale: "en-US",
    flag: "🇺🇸",
    label: "USA",
  },
  {
    value: "my",
    region: "CA",
    locale: "en-CA",
    flag: "🇨🇦",
    label: "CAN-En",
  },
  {
    value: "my",
    region: "CA",
    locale: "fr-CA",
    flag: "🇨🇦",
    label: "CAN-Fr",
  },
  {
    value: "pa",
    region: "PAN",
    locale: "en-PA",
    flag: "🇵🇦",
    label: "PAN-En",
  },
  {
    value: "pa",
    region: "PAN",
    locale: "es-PA",
    flag: "🇵🇦",
    label: "PAN-Es",
  },
  {
    value: "sg",
    region: "SING",
    locale: "en-SG",
    flag: "🇸🇬",
    label: "SG-En",
  },
  {
    value: "sg",
    region: "SING",
    locale: "zh-SG",
    flag: "🇸🇬",
    label: "新加坡",
  },
  {
    value: "ae",
    region: "UAE",
    locale: "en-AE",
    flag: "🇦🇪",
    label: "UAE-En",
  },
  {
    value: "ae",
    region: "UAE",
    locale: "ar-AE",
    flag: "🇦🇪",
    label: "ٱلْإِمَارَات",
  },
];

const englishRegions = [
  {
    value: "us",
    region: "USA",
    locale: "en-US",
    flag: "🇺🇸",
    label: "USA",
  },
  {
    value: "my",
    region: "CA",
    locale: "en-CA",
    flag: "🇨🇦",
    label: "CAN",
  },
  {
    value: "pa",
    region: "PAN",
    locale: "en-PA",
    flag: "🇵🇦",
    label: "PAN",
  },
  {
    value: "sg",
    region: "SING",
    locale: "en-SG",
    flag: "🇸🇬",
    label: "SG",
  },
  {
    value: "ae",
    region: "UAE",
    locale: "en-AE",
    flag: "🇦🇪",
    label: "UAE",
  },
];

const CountrySelector = () => {
  const { i18n } = useLingui();
  const hasFeatureToggles = useFeatureToggles();

  const showFrench = hasFeatureToggles("show_french");
  const options = showFrench ? allLanguageRegions : englishRegions;

  const handleLink = useCallback(
    (newSub, newLocale) => {
      let route = window.location.href;
      const oldSub = route.split(".")[0];
      let oldLocale = i18n.locale;
      route = route.replace(oldLocale, newLocale);
      return `https://${route.replace(oldSub, newSub)}`;
    },
    [i18n.locale],
  );

  const defaultRegion = options.find((el) => el.locale === i18n.locale);

  const handleOnchange = useCallback(
    (props) => {
      window.location.replace(handleLink(props.value, props.locale));
    },
    [handleLink],
  );

  const Option = (props) => {
    const { innerProps, innerRef } = props;

    return (
      <div ref={innerRef} {...innerProps} className="flag-wrapper">
        <ImageContainer className="img">
          <img
            width="24"
            height="24"
            alt={props.data.region + " flag"}
            src={flags[props.data.region]}
          />
        </ImageContainer>
        <Label>{props.data.label}</Label>
      </div>
    );
  };

  return (
    <Wrapper style={{ width: showFrench ? "152px" : "126px", height: "48px" }}>
      <Select
        onChange={handleOnchange}
        placeholder={null}
        isSearchable={false}
        options={options}
        components={{ Option, SingleValue: Option }}
        defaultValue={defaultRegion}
        styles={customStyles}
      />
    </Wrapper>
  );
};

export default CountrySelector;
const ImageContainer = styled.div`
  font-size: 24px;
  margin-right: 8px;
  line-height: 1;
`;
const Wrapper = styled.div`
  margin: auto;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.validGrey};
  padding: 6px;
  position: relative;
  cursor: pointer;

  input[id^="react-select"] {
    position: absolute;
  }

  .flag-wrapper {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  [class^="ValueContainer"] {
    ${ImageContainer} {
      flex: 0 0 60%;
    }
  }
`;

const Label = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.darkGrey};
  margin-left: 4px;
  margin-right: 8px;
`;
