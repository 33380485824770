import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { useNavigate, useParams } from "react-router";

import { login } from "containers/Authenticated/actions";
import { StackedInput as StackedInputBase } from "ui/inputs/StackedInput";
import PuiButton from "ui/buttons/PuiButton";

import { usePageViewEvent } from "hooks";
import Metrics from "helpers/metrics";
import { sendError } from "helpers/errorTracking";
import { userChangePassword } from "containers/Passwords/SetPassword/UserChangePasswordMutation";
import { refreshFeatureToggles } from "mutations/RefreshFeatureTogglesMutation";
import { checkResetToken } from "queries/CheckResetTokenQuery";
import { userPasswordCreate } from "mutations/UserPasswordCreateMutation";

import {BookACallContext} from "context/BookACallContext";

const Toggle = (hidden, toggleShow) => {
  return (
    <ToggleContainer>
      <pw-i-lined-show
        width={24}
        onClick={toggleShow}
        style={{ display: hidden ? "none" : "inherit", cursor: "pointer" }}
      />
      <pw-i-lined-hide
        width={24}
        onClick={toggleShow}
        style={{ display: hidden ? "inherit" : "none", cursor: "pointer" }}
      />
    </ToggleContainer>
  );
};

const CreatePasswordContainer = React.forwardRef((props, ref) => {
  const [hidden, setHidden] = useState(true);
  const toggleShow = () => setHidden(!hidden);
  return (
    <Container>
      <AdaptedInput
        type={hidden ? "password" : "text"}
        autoComplete="off"
        required
        SuffixComponent={Toggle(hidden, toggleShow)}
        {...props}
        style={{ paddingLeft: "36px" }}
        ref={ref}
      />
    </Container>
  );
});

const AdaptedInput = styled(StackedInputBase)`
  text-align: left;
  margin-bottom: 1rem;

  label {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.midGrey};
  }

  input {
    width: 100%;
    height: 48px;
    margin: 8px 0 0;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.validGrey} !important;
    background-color: ${({ theme }) => theme.white};
    padding-top: 5px;
    padding-left: 12px !important;
  }

  input[style*="background-image"] ~ [class*="ToggleContainer"] {
    right: 30px;
  }
`;

const Container = styled.div`
  position: relative;
`;

const ToggleContainer = styled.div`
  &:last-child {
    position: absolute;
    bottom: 10px;
    right: 6px;
    display: flex;
    justify-content: flex-start;
  }
`;

const ActionBar = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const CreatePasswordBase = () => {
  const { i18n } = useLingui();
  const confirmPasswordRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const {refresh: bookCallRefresh} = useContext(BookACallContext);

  usePageViewEvent("login: create-password-page");

  const resetConfirmationValidity = () => {
    if (confirmPasswordRef.current) {
      const node = confirmPasswordRef.current;
      node.setCustomValidity("");
      node.reportValidity();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    if (formData.get("password") !== formData.get("confirmPassword")) {
      const confirmPasswordEl = e.target.elements.namedItem("confirmPassword");
      confirmPasswordEl.setCustomValidity(
        t`Password confirmation does not match`,
      );
      confirmPasswordEl.reportValidity();
    } else {
      const { createToken } = params;

      try {
        const { userChangePassword: response } = await userChangePassword({
          password: formData.get("password"),
          resetToken: createToken,
          type: "CREATE",
        });

        Metrics.track("login: create-password-success");
        await login(response);
        await refreshFeatureToggles();
        bookCallRefresh();
        if (response.user.role === "ADVISOR"|| response.user.role === "SALES") {
          navigate(`/${i18n.locale}/partner`);
        } else {
          navigate(`/${i18n.locale}`);
        }
      } catch (e) {
        Metrics.track("login: create-password-fail");
        sendError("Create password error", null, e);
      }
    }
  };

  return (
    <pw-row
      style={{
        justifyContent: "center",
        position: "relative",
        bottom: "100px",
      }}
      gutter="sm"
    >
      <pw-col xxxl="3" xl="5" lg="6" md="8" sm="10" gutter="xl">
        <pw-col xxxl="12" gutter="md">
          <LoginTextTitle>Create new password</LoginTextTitle>
        </pw-col>
        <form onSubmit={onSubmit}>
          <div className="passwordContainer">
            <CreatePasswordContainer
              label={t`Password`}
              name="password"
              className="password"
              minLength={8}
            />
            <pw-body size="small" color="lightgrey">
              <Trans>
                Your password must be at least eight characters long.
              </Trans>
            </pw-body>
          </div>
          <CreatePasswordContainer
            label={t`Confirm password`}
            name="confirmPassword"
            minLength={8}
            ref={confirmPasswordRef}
            onChange={resetConfirmationValidity}
          />
          <ActionBar>
            <PuiButton type="submit" tabIndex="0">
              <Trans>Set new password</Trans>
            </PuiButton>
          </ActionBar>
        </form>
      </pw-col>
    </pw-row>
  );
};

const CreatePasswordController = (props) => {
  const { createToken } = useParams();
  const [tokenValid, setTokenValid] = useState(false);
  const [resentEmail, setResentEmail] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const { checkResetToken: validity } = await checkResetToken({
          resetToken: createToken,
          type: "CREATE",
        });
        if (validity === "expired") {
          await userPasswordCreate({ createToken });
          setResentEmail(true);
        } else {
          setTokenValid(true);
        }
      } catch (e) {
        sendError("CreateTokenCheckError", null, e);
      }
    };
    validateToken();
  }, [createToken]);

  return (
    <>
      {tokenValid && <CreatePasswordBase {...props} />};
      {resentEmail && (
        <pw-row
          style={{
            justifyContent: "center",
            position: "relative",
            bottom: "100px",
          }}
          gutter="sm"
        >
          <pw-col xxxl="3" xl="5" lg="6" md="8" sm="10" gutter="xl">
            <strong>
              <Trans>Oh no!</Trans>
            </strong>
            <Trans>
              {" "}
              It looks like your email link has expired. Not to worry. We've
              sent you another one. You should receive a confirmation email in
              your inbox in the next few minutes with some instructions.
            </Trans>
          </pw-col>
        </pw-row>
      )}
    </>
  );
};

export const CreatePassword = styled(CreatePasswordController)`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.textGrey};

  a {
    text-decoration: underline;
  }

  .passwordContainer {
    margin-bottom: 1rem;
  }

  .password {
    margin-bottom: 0;
  }
`;

const LoginTextTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.midGrey};
  position: relative;
  margin-bottom: 20px;
`;
