import React, { useEffect } from "react";
import styled from "styled-components";
import { Trans, t } from "@lingui/macro";
import Metrics from "helpers/metrics";
import { StackedInput as StackedInputBase } from "ui/inputs/StackedInput";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";
import { SignupLinkContainer } from "containers/LoginPage";
import CountrySelector from "components/CountrySelector";
import PuiButton from "ui/buttons/PuiButton";
import {useFeatureToggles} from "hooks";

const SignupLink = () => {
  const { i18n } = useLingui();

  return (
    <SignupLinkContainer>
      <nobr><Trans>Don't have an account? </Trans></nobr>
      &nbsp;
      <Link
        to={`/${i18n.locale}/discovery`}
        onClick={() => Metrics.track(`login: initial-page-signup-click`)}
      >
        <nobr><Trans>Sign up</Trans></nobr>
      </Link>
    </SignupLinkContainer>
  );
};
const EmailBase = ({ className, email, loginError, onChange, onSubmit }) => {
  const hasFeatureToggles = useFeatureToggles();

  useEffect(() => {
    Metrics.track("User Lands On Initial Page On Login");
  }, []);

  const onInvalid = ({ target }) => {
    const errorMessage = t`Please enter a valid email address`;
    target.setCustomValidity(errorMessage);
  };

  const resetValidity = ({ target }) => {
    target.setCustomValidity("");
    target.checkValidity();
  };

  return (
    <pw-row style={{ justifyContent: "center" }} gutter="md">
      <pw-col
        xxxl="4"
        xl="5"
        lg="6"
        md="8"
        sm="10"
        gutter="sm"
        style={{ maxWidth: "384px" }}
      >
        <EmailForm
          className={className}
          onSubmit={onSubmit}
          onChange={resetValidity}
        >
          <pw-row gutter="md">
            <pw-col xxxl="12" gutter="md">
              <LoginTextTitle>Login</LoginTextTitle>
            </pw-col>
            <pw-col xxxl="12" gutter="md">
              <Error>{loginError}</Error>
              <AdaptedInput
                label={t`Email address`}
                name="email"
                onChange={onChange}
                onInvalid={onInvalid}
                value={email}
                required
                type="email"
                autoFocus
              />
            </pw-col>
            <pw-col xxxl="12" gutter="md">
              <pw-row
                gutter="sm"
                style={{
                  alignItems: "center",
                  position: "relative",
                  bottom: "7px",
                }}
              >
                <pw-col nm="7" sm="6" gutter="sm">
                  <SignupLink />
                </pw-col>
                {hasFeatureToggles("locale_selector") &&
                  <pw-col nm="5" sm="6" gutter="sm">
                    <OffsetLanguageContainer>
                      <CountrySelector />
                    </OffsetLanguageContainer>
                  </pw-col>
                }
              </pw-row>
            </pw-col>
            <pw-col xxxl="12" gutter="md">
              <PuiButton type="submit">
                <Trans>Continue login</Trans>
              </PuiButton>
            </pw-col>
          </pw-row>
        </EmailForm>
      </pw-col>
    </pw-row>
  );
};

export const Email = styled(EmailBase)`
  margin-bottom: 1rem;

  a {
    text-decoration: underline;
  }
`;

const Error = styled.p`
  color: ${({ theme }) => theme.danger};
  font-size: 1rem;
  margin-top: 0.75rem;
`;

const AdaptedInput = styled(StackedInputBase)`
  text-align: left;
  margin-bottom: 1rem;

  label {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({ theme }) => theme.midGrey};
  }

  input {
    height: 48px;
    margin: 8px 0 0;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.validGrey} !important;
    background-color: ${({ theme }) => theme.white};
    padding-top: 5px;
    padding-left: 12px !important;
  }

  input::placeholder {
    padding-left: 12px;
  }
`;

const OffsetLanguageContainer = styled.div`
  > div:first-child {
    border: 1px solid ${({ theme }) => theme.validGrey};
    padding: 5px 10px;
    border-radius: 8px;
    max-width: 100%;
    cursor: pointer;
    .flag-wrapper {
      position: relative;
      top: 5px;
    }
    svg {
      position: relative;
      top: 6px;
    }
    @media screen and (max-width: 390px) {
      position: relative;
      left: 0px;
    }
  }
`;

const EmailForm = styled.form`
  position: relative;
  bottom: 100px;
  width: 100%;
`;

const LoginTextTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.midGrey};
  position: relative;
  margin-bottom: 10px;
`;
