import React, { useContext, useState } from "react";
import { find } from "lodash";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { useNavigate, useLocation } from "react-router";
import { getSignInAction } from "queries/SignInActionQuery";

import {
  Email,
  Password,
  SmsVerify,
  ConfirmationEmail,
  CreatePassword,
  BackButton,
} from "./components";
import Metrics from "helpers/metrics";
import { sendError } from "helpers/errorTracking";
import { login, postLoginRedirect } from "containers/Authenticated/actions";
import { userSignIn } from "mutations/UserSignInMutation";
import { userPasswordCreate } from "mutations/UserPasswordCreateMutation";
import { userSmsVerify } from "mutations/UserSmsVerifyMutation";
import {BookACallContext} from "context/BookACallContext";

const Login = (props) => {
  const [state, setState] = useState({
    data: {
      email: "",
      password: "",
      code: "",
    },
    action: props.action || "email",
    errors: {
      password: null,
      code: null,
    },
    loginError: null,
    smsLastFour: null
  });

  const [confirmationEmailStatus, setConfirmationEmailStatus] =
    useState("unsent");
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const location = useLocation();
  const {refresh: bookCallRefresh} = useContext(BookACallContext);

  const setAction = (action) => setState({ ...state, action });

  const sendCreatePasswordEmail = async () => {
    const { email } = state.data;

    try {
      setConfirmationEmailStatus("sending");
      await userPasswordCreate({ email });
      setAction("confirmationEmail");
      setConfirmationEmailStatus("sent");
    } catch (_error) {
      setConfirmationEmailStatus("error");
    }
  };

  const handleChange = ({ target: { name, value } }) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
      errors: {
        password: null,
      },
    });

  const setGeneralError = () =>
    setState({
      ...state,
      loginError: i18n._(
        t`Something went wrong. Please try again in a few moments.`,
      ),
      action: "email",
      data: {
        ...state.data,
        email: "",
        password: "",
      },
    });

  const goBackToEmail = () => {
    setState({ ...state, loginError: null, action: "email" });
  };
  const handleSubmitEmail = async (event) => {
    const { email } = state.data;

    event.preventDefault();
    try {
      const { signInAction } = await getSignInAction({ email });

      if (signInAction === "create_password") {
        sendCreatePasswordEmail();
      } else {
        setAction("password");
      }
    } catch (e) {
      sendError("In Login.handleSubmitEmail()", null, e);
      setGeneralError();
    }
  };

  const handleLogin = async (payload) => {
    const { redirect } = props;

    Metrics.track("login: login-success");
    await login(payload);
    bookCallRefresh();
    postLoginRedirect(payload, redirect, navigate, i18n, location);
  };

  const handleLoginMutationErrors = async (mutationErrors) => {
    Metrics.track("login: login-fail");
    const nonexistentUserError = find(mutationErrors, {
      message: t`User does not exist`,
    });

    const invalidLoginError = find(mutationErrors, {
      message: t`Email or password not found.`,
    });
    const tooManyLoginAttemptsError = find(mutationErrors, {
      message: t`Too many login attempts have been made. Throttling is in effect`,
    });
    const smsVerificationFailedError = find(mutationErrors, {
      message: t`Incorrect verification code`,
    });
    if (tooManyLoginAttemptsError) {
      setState({
        ...state,
        loginError: tooManyLoginAttemptsError.message,
        action: "password",
        data: { ...state.data, password: "" },
      });
    } else if (nonexistentUserError) {
      setState({
        ...state,
        loginError: t`Email not found.`,
        action: "email",
        data: { ...state.data, password: "" },
      });
    } else if (invalidLoginError) {
      setState({
        ...state,
        loginError: invalidLoginError.message,
        action: "password",
        data: { ...state.data, password: "" },
      });
    } else if (smsVerificationFailedError) {
      setState({
        ...state,
        loginError: smsVerificationFailedError.message,
        action: "sms",
        data: { ...state.data, code: "" },
      });
    } else {
      sendError("In Login.handleSubmitPassword()", { mutationErrors });
      setGeneralError();
    }
  };

  const handleSubmitPassword = async (event) => {
    const { email, password } = state.data;
    event.preventDefault();
    try {
      const { userSignIn: payload } = await userSignIn({ email, password });

      if (payload.doSms?.length > 0) {
        setState({
          ...state,
          action: "sms",
          data: { ...state.data, smsLastFour: payload.doSms },
          loginError: null,
        });
      } else {
        handleLogin(payload);
      }
    } catch (e) {
      handleLoginMutationErrors(e.mutationErrors || e.mutationError.errors);
    }
  };

  const handleSubmitSms = async (event) => {
    const { email, code } = state.data;
    event.preventDefault();
    try {
      const { userSmsVerify: payload } = await userSmsVerify({ email, code });
      handleLogin(payload);
    } catch ({ mutationErrors }) {
      sendError("In Login.handleSubmitSms()", { mutationErrors });
      handleLoginMutationErrors(mutationErrors);
    }
  };

  const actions = {
    email: () => (
      <Email
        onChange={handleChange}
        email={state.data.email}
        onSubmit={handleSubmitEmail}
        loginError={state.loginError}
      />
    ),
    password: () => (
      <Password
        onChange={handleChange}
        email={state.data.email}
        password={state.data.password}
        onSubmit={handleSubmitPassword}
        loginError={state.loginError}
      >
        <BackButton onClick={goBackToEmail} />
      </Password>
    ),
    sms: () => (
      <SmsVerify
        onChange={handleChange}
        email={state.data.email}
        password={state.data.password}
        onSubmit={handleSubmitSms}
        loginError={state.loginError}
        smsLastFour={state.data.smsLastFour}
      >
        <BackButton onClick={goBackToEmail} />
      </SmsVerify>
    ),
    confirmationEmail: () => (
      <ConfirmationEmail
        status={confirmationEmailStatus}
        onClick={sendCreatePasswordEmail}
      />
    ),
    createPassword: () => <CreatePassword />,
  };

  return <div style={{ width: "100%" }}>{actions[state.action]()}</div>;
};

export default Login;
