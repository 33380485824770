import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import { get } from "lodash";

/**
 *
 *
 * @export
 * @param {*} {
 *   children = "Label",
 *   type,
 *   isDisabled,
 *   isSecondary,
 *   isTertiary,
 *   isDanger,
 *   className,
 *   style
 * }
 * @returns
 */
export default function PuiButton({
  children = "Label",
  type,
  isDisabled,
  isSecondary,
  isTertiary,
  isDanger,
  onClick,
  style,
  className,
}) {
  return (
    <StyledPuiButton
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={classnames(
        {
          secondary: isSecondary,
          tertiary: isTertiary,
          danger: isDanger,
        },
        className,
      )}
      style={{ ...style }}
    >
      {children}
    </StyledPuiButton>
  );
}

export const StyledPuiButton = styled.button`
  box-sizing: border-box;
  border: 2px solid ${(props) => get(props, "theme.primary", "inherit")};;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => get(props, "theme.primary", "inherit")};;
  border-radius: 30px;
  line-height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    transform: translateY(-5%);
    box-shadow: 0 5px 10px 0 rgba(${({ theme }) => theme.primaryRGB}, 0.5);
  }
  &:active:not(:disabled) {
    background: ${({ theme }) => theme.alternativeGreen};
    border-color: ${({ theme }) => theme.alternativeGreen};
    transform: translateY(0);
    box-shadow: 0 3px 8px 0 rgba(${({ theme }) => theme.primaryRGB}, 0.5);
  }
  span {
    display: block;
    padding: 0 35px;
  }

  &.secondary {
    background: ${({ theme }) => theme.alternativeGreen};
    border-color: ${({ theme }) => theme.alternativeGreen};

    &:active:not(:disabled) {
      background: ${({ theme }) => theme.borderGreen};
      border-color: ${({ theme }) => theme.borderGreen};
    }
  }

  &.tertiary {
    background: ${({ theme }) => theme.white};
    border-color: currentColor;
    color: ${({ theme }) => theme.alternativeGreen};

    &:active {
      background: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.borderGreen};
      color: ${({ theme }) => theme.alternativeGreen};
    }
  }

  &.danger {
    background: ${({ theme }) => theme.white};
    border-color: currentColor;
    color: ${({ theme }) => theme.altDanger};

    &:active:not(:disabled) {
      background: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.altDanger};
      color: ${({ theme }) => theme.altDanger};
      box-shadow: 0 3px 8px 0 rgba(${({ theme }) => theme.altDangerRGB}, 0.5);
    }

    &:hover:not(:disabled) {
      box-shadow: 0 5px 10px 0 rgba(${({ theme }) => theme.altDangerRGB}, 0.5);
    }
  }

  &.secondary,
  &.tertiary &.isDanger {
    border-radius: 24px;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
  }

  &:disabled {
    background: ${({ theme }) => theme.alternateSectionGrey};
    color: ${({ theme }) => theme.validGrey};
    cursor: not-allowed;
    border: none;
  }
`;
