import { get, fromPairs } from "lodash";

import { refreshFeatureToggles } from "mutations/RefreshFeatureTogglesMutation";
import { sendError } from "helpers/errorTracking";

/* global analytics */
let currentFeatureToggles = {};

export const refreshCurrentFeatureToggles = async () => {
  try {
    const payload = await refreshFeatureToggles();
    const featureToggles = get(
      payload,
      "refreshFeatureToggles.featureToggles",
      {},
    );
    const featureToggleStrings = fromPairs(
      featureToggles.map(({ name, enabled }) => [`toggle_${name}`, enabled]),
    );
    currentFeatureToggles = { ...featureToggleStrings };
  } catch (e) {
    sendError(
      `In Metrics.${refreshCurrentFeatureToggles.caller.name}()`,
      null,
      e,
    );
  }
};

const segmentInitialize = (segment_api_key) => {
  // Create a queue, but don't obliterate an existing one!
  var analytics = (window.analytics = window.analytics || []);
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
    "addSourceMiddleware",
    "addIntegrationMiddleware",
    "setAnonymousId",
    "addDestinationMiddleware",
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
    // Insert our script next to the first script element.
    var first = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = segment_api_key;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = "4.15.2";
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(segment_api_key);
  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page();
};

const MetricsStub = {
  track: (event, payload) =>
    console.log(`Metrics.track(): ${event}`, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    }),
  page: (category, name, payload) =>
    console.log(`Metrics.page(): ${category} ${name}`, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    }),
  identify: (id, traits, callback) => {
    console.log(`Metrics.identify(): ${id}`, traits);
    refreshCurrentFeatureToggles();
    if (callback) {
      setTimeout(callback, 300);
    }
  },
  alias: (data) => {
    console.log("Metrics.alias():", data);
    refreshCurrentFeatureToggles();
  },
  setup: () => console.log("Metrics.setup()"),
};

const Metrics = {
  track: (event, payload) => {
    analytics.track(event, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    });
  },
  page: (category, name, payload) => {
    analytics.page(category, name, {
      ...payload,
      featureToggles: { ...currentFeatureToggles },
    });
  },
  identify: (id, traits, callback) => {
    const mergedTraits = { ...traits, region: process.env.REACT_APP_REGION };
    analytics.identify(id, { traits: mergedTraits }, {}, callback);
    refreshCurrentFeatureToggles();
  },
  alias: ({ id }) => {
    analytics.alias(id);
    analytics.identify(id, {
      traits: { region: process.env.REACT_APP_REGION },
    });
    refreshCurrentFeatureToggles();
  },
  setup: () => segmentInitialize(process.env.REACT_APP_SEGMENT_API_KEY),
};

const getMetrics = () =>
  process.env.REACT_APP_METRICS_ENABLED ? Metrics : MetricsStub;

export default getMetrics();
