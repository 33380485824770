import "react-app-polyfill/stable";
import "whatwg-fetch";
import { isIE } from "react-device-detect";

import React, { useState, useEffect } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import URLSearchParams from "url-search-params";
import { StyleSheetManager } from "styled-components";

import I18nLoader from "components/I18nLoader";
import setupErrorTracking from "helpers/errorTracking";

import relayEnvironment from "helpers/RelayEnvironment";
import Metrics from "helpers/metrics";
import ThemeProvider from "themes/ThemeProvider";
import App from "./containers/App";
import ErrorBoundary from "components/ErrorBoundary";
import defaultLang from "helpers/defaultLanguage";

import "sanitize.css/sanitize.css";

// load planswell design system; https://github.com/planswell/design
import { applyPolyfills, defineCustomElements } from "design/loader";
import "design/dist/design/design.css";

// Apparently Chrome will detect String.slice that does nothing and return a reference
const copyString = (x) => ` ${x}`.slice(1);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

setupErrorTracking();

const localeRegex = /^\/[a-z]{2}-[A-Z]{2}/;

const AppContainer = () => {
  const [language, setLanguage] = useState(copyString(defaultLang));

  const { pathname, search } = window.location;

  useEffect(() => {
    if (!pathname.match(localeRegex)) {
      const newPath = `/${defaultLang}${pathname}${search}`;

      window.location.replace(newPath);
    }
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isIE) {
    return (
      <div>
        {process.env.REACT_APP_BRAND} does not support Internet Explorer. Download
        Chrome/Opera/Firefox/MS Edge
      </div>
    );
  }

  const updateLanguage = ({ language }) => {
    setLanguage(language);
    const url = window.location.toString();
    const newUrl = url.replace(localeRegex, `/${language}`);
    if (url !== newUrl) {
      window.location = newUrl;
    }
  };

  return (
    <React.StrictMode>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <StyleSheetManager enableVendorPrefixes>
          <ThemeProvider>
            <ErrorBoundary>
              <I18nLoader language={language} updateLanguage={updateLanguage}>
                <Router>
                  <Routes>
                    <Route
                      path="/:localeName/*"
                      element={<App updateLanguage={updateLanguage} />}
                    />
                    <Route
                      path="/"
                      element={<Navigate to={`${defaultLang}`} />}
                    />
                  </Routes>
                </Router>
              </I18nLoader>
            </ErrorBoundary>
          </ThemeProvider>
        </StyleSheetManager>
      </RelayEnvironmentProvider>
    </React.StrictMode>
  );
};

const domMount = document.getElementById("root");
const root = createRoot(domMount);
root.render(<AppContainer />);

Metrics.setup();
window.analytics?.ready(() => {
  const deviceId = new URLSearchParams(window.location.search).get("did");
  if (deviceId) {
    window.amplitude?.setDeviceId(deviceId);
  }
});
